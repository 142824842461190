import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import "@fontsource/special-elite"
import Logo from '../logo.png';

const pages = [{ 'header': 'Demo Vid', 'link': 'https://youtu.be/0drvtuvoobM' }, { 'header': 'Blog', 'link': 'https://page-shot.blogspot.com/' }, { 'header': 'Mobile App', 'link': 'https://play.google.com/store/apps/details?id=zinc.android.pageshotapp' }];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleMenuSelect = (event) => {
        handleCloseNavMenu();
        if (pages[event.target.id].link)
            window.open(pages[event.target.id].link);
    };

    return (
        <AppBar position="static" sx={{ background: '#282c34' }}>
            <Container maxWidth="lg">
                <Toolbar elevation={20} disableGutters>
                    <IconButton sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, mb: 1}} >
                        <img src={Logo} className="App-logo" alt='page-shot.com' href='/'/>
                    </IconButton>
                    <Typography
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Special Elite',
                            fontWeight: 500,
                            fontSize: 18,
                            letterSpacing: '.2rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Page-Shot
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="Menu options"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page,index) => (
                                <MenuItem key={page.header} onClick={handleMenuSelect}>
                                    <Typography
                                        textAlign="center"
                                        id={index}
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: 12,
                                            letterSpacing: '.05rem',
                                            color: 'inherit'
                                        }}
                                    >{page.header}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <IconButton sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} href='/'>
                        <img src={Logo} className="App-logo" alt='page-shot.com' />
                    </IconButton>
                    <Typography
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mt: 1,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'Special Elite',
                            fontWeight: 500,
                            fontSize: 16,
                            letterSpacing: '.2rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Page-Shot
                    </Typography>
                    <Box sx={{ flexGrow: 1, justifyContent:"flex-end", display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.header}
                                onClick={handleCloseNavMenu}
                                sx={{
                                    color: 'white',
                                    display: 'block',
                                    fontFamily: 'Roboto',
                                    fontWeight: 500,
                                    letterSpacing: '.1rem',
                                    fontSize: 14,
                                    ml: 1,
                                }}
                                href={page.link}
                                target="_blank"
                            >
                                {page.header}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;