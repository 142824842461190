import '../App.css';
import * as React from 'react';
//import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
//import CardActions from '@mui/material/CardActions';
//import Collapse from '@mui/material/Collapse';
//import Avatar from '@mui/material/Avatar';
/*import IconButton from '@mui/material/IconButton';*/
import Typography from '@mui/material/Typography';
//import { red } from '@mui/material/colors';
//import FavoriteIcon from '@mui/icons-material/Favorite';
//import ShareIcon from '@mui/icons-material/Share';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
//import { borders } from '@mui/system';

//const ExpandMore = styled((props) => {
//    const { expand, ...other } = props;
//    return <IconButton {...other} />;
//})(({ theme, expand }) => ({
//    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//    marginLeft: 'auto',
//    transition: theme.transitions.create('transform', {
//        duration: theme.transitions.duration.shortest,
//    }),
//}));

function PageshotImgCard(props) {

    const [imgWidth, setImgWidth] = React.useState(0);
    const [imgHeight, setImgHeight] = React.useState(0);
    //const [expanded, setExpanded] = React.useState(false);

    //const handleExpandClick = () => {
    //    setExpanded(!expanded);
    //};

    const getImageSize = async function () {
        let imgDom = document.getElementById("pageshot-img");
        if (imgDom) {
            setImgWidth(imgDom.naturalWidth);
            setImgHeight(imgDom.naturalHeight);
        }
    }

    return (
        <Container maxWidth={props.isMobilePhone ? "sm" : "lg"} >
            <Box sx={{ flexGrow: 1, mt: 10, color: '#282c34', display: props.isFetching && props.pageshotImg === null ? 'block' : 'none' }}>
                <CircularProgress sx={{ color: '#282c34' }} />
            </Box>
            <Card elevation={2} sx={{
                mt: 8,
                mb: 2,
                display: props.pageshotImg === null ? 'none' : 'block', 
                borderRadius: 2
            }} >
                <CardMedia
                    id="pageshot-img"
                    component="img"
                    height="auto"
                    width="auto"
                    image={props.pageshotImg ? URL.createObjectURL(props.pageshotImg) : ""}
                    onLoad={getImageSize}
                />
                <CardContent sx={{ textAlign: 'start', mt: 5, mb: 0, pb: 0 }}>
                    <Divider sx={{mt:0, mb:2}} orientation="horizontal" variant="middle" />
                    <Typography variant="body2" color="inhert" noWrap>
                        Resolution: {imgWidth}px (w), {imgHeight}px (h)
                    </Typography>
                    <Typography variant="body2" color="inhert" noWrap>
                        Size: {props.pageshotImg ? Math.round(props.pageshotImg.size / 1024 / 1024 * 100) / 100 : ""}MB
                    </Typography>
                    <Typography variant="body2" color="textinhert">
                        Created: {(new Date(props.timestamp)).toString().split('GMT')[0]}
                    </Typography>
                </CardContent>
                {/*<CardActions sx={{ display: 'flex', justifyContent: "flex-end", pt: 0 }} disableSpacing>*/}
                {/*    <IconButton aria-label="share">*/}
                {/*        <ShareIcon />*/}
                {/*    </IconButton>*/}
                    {/*<ExpandMore*/}
                    {/*    expand={expanded}*/}
                    {/*    onClick={handleExpandClick}*/}
                    {/*    aria-expanded={expanded}*/}
                    {/*    aria-label="show more"*/}
                    {/*>*/}
                    {/*    <ExpandMoreIcon />*/}
                    {/*</ExpandMore>*/}
                {/*</CardActions>*/}
                {/*<Collapse in={expanded} timeout="auto" unmountOnExit>*/}
                {/*    <CardContent>*/}
                {/*        <Typography paragraph>Method:</Typography>*/}
                {/*        <Typography paragraph>*/}
                {/*            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set*/}
                {/*            aside for 10 minutes.*/}
                {/*        </Typography>*/}
                {/*        <Typography paragraph>*/}
                {/*            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over*/}
                {/*            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring*/}
                {/*            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a*/}
                {/*            large plate and set aside, leaving chicken and chorizo in the pan. Add*/}
                {/*            piment�n, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,*/}
                {/*            stirring often until thickened and fragrant, about 10 minutes. Add*/}
                {/*            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.*/}
                {/*        </Typography>*/}
                {/*        <Typography paragraph>*/}
                {/*            Add rice and stir very gently to distribute. Top with artichokes and*/}
                {/*            peppers, and cook without stirring, until most of the liquid is absorbed,*/}
                {/*            15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and*/}
                {/*            mussels, tucking them down into the rice, and cook again without*/}
                {/*            stirring, until mussels have opened and rice is just tender, 5 to 7*/}
                {/*            minutes more. (Discard any mussels that don&apos;t open.)*/}
                {/*        </Typography>*/}
                {/*        <Typography>*/}
                {/*            Set aside off of the heat to let rest for 10 minutes, and then serve.*/}
                {/*        </Typography>*/}
                {/*    </CardContent>*/}
                {/*</Collapse>*/}
            </Card>
        </Container>
    );
}

export default PageshotImgCard