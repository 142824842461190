import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function MastHead() {

    return (
        <Box sx={{
            display: 'block',
            flexWrap: 'wrap'
            }}>
            <Typography
                variant='h1'
                sx={{
                    mt: 6, ml: 5, mr: 5, mb:5,
                    textAlign: 'center',
                    flexGrow: 1,
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    letterSpacing: '.2rem', 
                    fontSize: { xs: 18, md: 24 },
                    color: 'black'
                }}
            >
                Capture webpages as images with Page-Shot
            </Typography>
        </Box>
    );
}
export default MastHead;