import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import "@fontsource/special-elite"
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import ComputerIcon from '@mui/icons-material/Computer';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';


function InputForm(props) {

    const [url, setUrl] = useState("https://www.example.com");
    const [isFullpage, setIsFullpage] = useState(0);
    const [deviceSelected, setDeviceSelected] = useState(1);

    const deviceData = [
        null,
        { "index": 1, "deviceName": "Desktop", "label": "1200x800", "browserWidth": 1200, "browserHeight": 800, "isMobilePhone": false },
        { "index": 2, "deviceName": "Desktop", "label": "1920x1080", "browserWidth": 1920, "browserHeight": 1080, "isMobilePhone": false },
        { "index": 3, "deviceName": "Desktop", "label": "1024x768", "browserWidth": 1024, "browserHeight": 768, "isMobilePhone": false },
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        { "index": 11, "deviceName": "iPhone X", "label": "iPhone X", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": true },
        { "index": 12, "deviceName": "iPhone 13", "label": "iPhone 13", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": true },
        { "index": 13, "deviceName": "iPhone 13 Pro Max", "label": "iPhone 13 Pro Max", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": true },
        { "index": 14, "deviceName": "iPad Mini", "label": "iPad Mini", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": false },
        { "index": 15, "deviceName": "iPad Pro", "label": "iPad Pro", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": false },
        { "index": 16, "deviceName": "Galaxy S9+", "label": "Galaxy S9+", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": true },
        { "index": 17, "deviceName": "Galaxy Tab S4", "label": "Galaxy Tab S4", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": false },
        { "index": 18, "deviceName": "Pixel 5", "label": "Pixel 5", "browserWidth": 0, "browserHeight": 0, "isMobilePhone": true }
    ];

    const handleSelectChange = (event) => {
        const newSelection = event.target.value;
        if (newSelection > 0 && deviceData[newSelection]) {
            setDeviceSelected(newSelection);
            props.reset();
        }
        document.getElementById("select-device").blur();
    };
    const handleSelectChange_fullpage = (event) => {
        const newSelection = event.target.value;
        if (newSelection === 0 || newSelection === 1) {
            setIsFullpage(newSelection);
            props.reset();
        }
        document.getElementById("select-is-fullpage").blur();
    };

    const handleChange = (event) => {
        if (event.target.value === "")
            setUrl("https://");
        else
            setUrl(event.target.value.toLowerCase());

        props.reset();
    };

    const handleSubmit = function () {
        props.reset();

        props.onSubmit(url, deviceData[deviceSelected].deviceName, deviceData[deviceSelected].browserWidth, deviceData[deviceSelected].browserHeight, isFullpage === 0 ? false : true, deviceData[deviceSelected].isMobilePhone);
    };

    const handleClear = () => {
        setUrl("https://");
        props.reset();
    };


    const handleFocus = (event) => {
        if (event.target.value === "https://www.example.com")
            setUrl("https://");
    };

    const onKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            setUrl(e.target.value);
            document.getElementById("outlined-url-input").blur();
            handleSubmit();
        }
    }

    return (
        <Container maxWidth='md'>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { width: { xs: '100%', md: '100%' } },
                    '& fieldset': {
                        borderRadius: '30px',
                    },
                    '& input': {
                        paddingLeft: '30px',
                        fontSize: '16px',
                    },
                    '& .MuiFormHelperText-root': { ml: '30px' }
                }}
            >
                <TextField
                    id="outlined-url-input"
                    value={url}
                    helperText={props.errorMsg}
                    type="url"
                    error={props.hasError}
                    onKeyDown={onKeyPress}
                    onFocus={handleFocus}
                    InputProps={{
                        style: {
                            height: "50px",
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton sx={{ mr: 1 }} onClick={handleClear} >
                                    <ClearIcon />
                                </IconButton>
                                <Divider sx={{ height: '35px' }} orientation="vertical" variant="middle" />
                                <IconButton sx={{ ml: 1 }} onClick={handleSubmit} >
                                    <PhotoCameraIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                    noValidate
                    autoComplete="off"
                    spellCheck="off"
                />
            </Box>
            <Box
                component="form"
                sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <FormControl variant="standard">
                    <Select
                        id="select-device"
                        value={deviceSelected}
                        label="Device Option"
                        onChange={handleSelectChange}
                        sx={{ fontSize: '13px', fontWeight: '600', letterSpacing: '.05rem' }}
                    >
                        {deviceData.map((device, index) => {
                            if (index === 0)
                                return <MenuItem key={index} value={index} style={{ fontSize: '12px', height: '30px' }}><ListItemIcon><ComputerIcon fontSize="small" /></ListItemIcon>DESKTOP</MenuItem>
                            else if (index === 10)
                                return <MenuItem key={index} value={index} style={{ fontSize: '12px', height: '30px' }}><ListItemIcon><DevicesOtherIcon fontSize="small" /></ListItemIcon>DEVICES</MenuItem>
                            else {
                                if (device)
                                    return <MenuItem key={index} value={index} style={{ fontSize: '12px', height: '25px' }}>{device.label}</MenuItem>
                                else
                                    return null;
                            }
                        })}
                    </Select>
                </FormControl >
                <FormControl variant="standard">
                    <Select
                        id="select-is-fullpage"
                        value={isFullpage}
                        label="Fullpage Pageshot"
                        onChange={handleSelectChange_fullpage}
                        sx={{ fontSize: '13px', fontWeight: '600', ml: 4, letterSpacing: '.05rem' }}
                    >
                        <MenuItem key={0} value={0} style={{ fontSize: '12px', height: '25px' }}>Partial-page</MenuItem>
                        <MenuItem key={1} value={1} style={{ fontSize: '12px', height: '25px' }}>Full-page</MenuItem>

                    </Select>
                </FormControl >
            </Box>
        </Container>
    );
}
export default InputForm;