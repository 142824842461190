import './App.css';
import { useState } from 'react';
import isURL from 'validator/lib/isURL'
import Container from '@mui/material/Container';
import InputForm from './components/inputForm.js'
import MastHead from './components/mastHead.js'
import PageshotImgCard from './components/pageshotImgCard.js'
import ResponsiveAppBar from './components/responsiveAppBar';
import "@fontsource/special-elite"

function App() {

    const [errMsg, setErrMsg] = useState("");
    const [hasErr, setHasErr] = useState(false);
    const [pageshotImg, setPageshotImg] = useState(null);
    const [timestamp, setTimestamp] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [isMobilePhone, setIsMobilePhone] = useState(false);

    const setErrorStatus = function (errorMsg) {
        setErrMsg(errorMsg);
        if (errorMsg)
            setHasErr(true);
        else
            setHasErr(false);
        setIsFetching(false);
    }

    const fetchPageshot = async function (url, deviceName, browserWidth, browserHeight, isFullpage, deviceIsMobilePhone) {
        if (isURL(url)) {
            setIsFetching(true);
            setIsMobilePhone(deviceIsMobilePhone);

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ url: url, deviceName: deviceName, browserWidth: browserWidth, browserHeight: browserHeight, isFullpage: isFullpage })
            };
            var startTime = new Date().getTime();

            await fetch("/api/pageshot", requestOptions)
                .then(response => {
                    switch (response.status) {
                        case 200:
                            return response.blob();
                        case 400:
                            setErrorStatus("Webpage cannot be loaded.");
                            throw new Error("Webpage cannot be loaded (SSL cert issue).");
                        case 404:
                            setErrorStatus("URL of webpage is invalid.");
                            throw new Error("URL cannot be resolved.");
                        case 408:
                            setErrorStatus("Pageshot of webpage has timeout. Please try again.");
                            throw new Error("Timeout has occured.");
                        case 500:
                            setErrorStatus("Error running pageshot of webpage with configurations specified.");
                            throw new Error("Unable to capture screenshot of webpage.");
                        default:
                            setErrorStatus("Pageshot service is unavailable. Please try again later.");
                            throw new Error("Pageshot API service cannot be reached.");
                    }
                })
                .then(image => {
                    setTimestamp(startTime);
                    //setTimeTaken(new Date().getTime() - startTime);
                    setPageshotImg(image);
                })
                .catch(error => {
                    console.error("Error: " + error.message);
                });
        }
        else 
            setErrorStatus("URL specified is invalid!!");
    };

    return (
        <div className="App">
            <header className="App-header">
                <ResponsiveAppBar />
                <Container maxWidth="lg" >
                    <MastHead />
                    <InputForm onSubmit={(url, deviceName, browserWidth, browserHeight, isFullpage, isDeviceMobilePhone) => { fetchPageshot(url, deviceName, browserWidth, browserHeight, isFullpage, isDeviceMobilePhone) }} reset={() => { setErrorStatus(null); setPageshotImg(null); setIsFetching(false); }} errorMsg={errMsg} hasError={hasErr} />
                    <PageshotImgCard isMobilePhone={isMobilePhone} pageshotImg={pageshotImg} timestamp={timestamp} isFetching={isFetching} />
                </Container>
            </header>
            <footer className="App-footer">&copy; 2023 www.page-shot.com All Rights Reserved.</footer>
        </div>
    );
}

export default App;
